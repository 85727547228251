import React, { useEffect } from "react";
import "./Users.css";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";

export default function Users() {
  async function getData() {
    const response = await fetch("http://localhost:3500/users");
    const datas = await response.json();
    return datas;
  }

  useEffect(() => {
    let tbody = document.getElementById("tbody");
    getData().then((data) => {
      data.map((item) => {
        tbody.insertAdjacentHTML(
          "beforeend",
          `
        <tr>
        <td>${item.id}</td>
        <td>${item.username}</td>
        <td>${item.password}</td>
      </tr>
        `
        );
      });
    });
  });

  return (
    <div className="users__container">
      <div className="users__wrapper">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>آیدی</th>
              <th>نام کاربری</th>
              <th>کلمه عبور</th>
            </tr>
          </thead>
          <tbody id="tbody"></tbody>
        </Table>
        <Link to="/home">صفحه اصلی</Link>
      </div>
    </div>
  );
}
