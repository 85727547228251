import React , {useRef} from "react";
import "./Box.css";
import { AiOutlineAreaChart , AiFillEdit , AiFillDelete } from "react-icons/ai";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { Link } from "react-router-dom";

export default function Box() {

    let ModalRef = useRef();

    function clickDotHandler () {
        ModalRef.current.classList.toggle('active');
    }

  return (
    <div className="box__container">
      <div className="box__wrapper">
        <div className="box__wrapper__top">
          <span>متن تستی</span>
          <span>آیکون تستی</span>
        </div>
        <div className="box__wrapper__center">
          <p>متن تستی برای نمایش</p>
        </div>
        <div className="box__wrapper__down">
          <Link to={"/chart"}>
            <AiOutlineAreaChart />
          </Link>
          <BiDotsVerticalRounded onClick={clickDotHandler} />
        </div>
        <div className="box__wrapper__modal" ref={ModalRef}>
          <span>
            <AiFillEdit className="svg__edit" />
            <p>ویرایش</p>
          </span>
          <span>
            <AiFillDelete className="svg__delete" />
            <p>حذف</p>
          </span>
        </div>
      </div>
    </div>
  );
}
