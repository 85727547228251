import React, { useRef } from "react";
import "./Add.css";

import { AiOutlinePlus } from "react-icons/ai";
import { BsAlarmFill } from "react-icons/bs";
import { CiRuler } from "react-icons/ci";
import { useState } from "react";

export default function Add() {
  let [showModalAdd, setShowModalAdd] = useState(false);

  let p1Ref = useRef();
  let p2Ref = useRef();
  let p3Ref = useRef();
  let p4Ref = useRef();
  let p5Ref = useRef();
  let p6Ref = useRef();
  let p7Ref = useRef();

  function focusHandler1() {
    p1Ref.current.classList.add("p__top");
  }

  function blurHandler1() {
    p1Ref.current.classList.remove("p__top");
  }

  function focusHandler2() {
    p2Ref.current.classList.add("p__top");
  }

  function blurHandler2() {
    p2Ref.current.classList.remove("p__top");
  }

  function focusHandler3() {
    p3Ref.current.classList.add("p__top");
  }

  function blurHandler3() {
    p3Ref.current.classList.remove("p__top");
  }

  function focusHandler4() {
    p4Ref.current.classList.add("p__top");
  }

  function blurHandler4() {
    p4Ref.current.classList.remove("p__top");
  }

  function focusHandler5() {
    p5Ref.current.classList.add("p__top");
  }

  function blurHandler5() {
    p5Ref.current.classList.remove("p__top");
  }

  function focusHandler6() {
    p6Ref.current.classList.add("p__top");
  }

  function blurHandler6() {
    p6Ref.current.classList.remove("p__top");
  }

  function focusHandler7() {
    p7Ref.current.classList.add("p__top");
  }

  function blurHandler7() {
    p7Ref.current.classList.remove("p__top");
  }

  function clickHandler() {
    setShowModalAdd(false);
  }

  function showModalHandler() {
    setShowModalAdd(true);
  }

  return (
    <div className="add__container">
      <AiOutlinePlus onClick={showModalHandler} />
      {showModalAdd && (
        <div className="add__container__modal">
          <div className="add__container__modal__wrapper">
            <h3>ثبت سنسور جدید</h3>
            <section>
              <div className="add__container__modal__wrapper__right">
                <input type="text" placeholder="شناسه *" />
                <input type="text" placeholder="نام *" />
                <div className="add__container__modal__wrapper__right__divs">
                  <span>
                    <BsAlarmFill />
                    <p>آلارم</p>
                  </span>
                  <span>
                    <div>
                      <p ref={p1Ref}>آلارم حداقل</p>
                      <input
                        type="number"
                        onFocus={focusHandler1}
                        onBlur={blurHandler1}
                      />
                    </div>
                    <div>
                      <p ref={p2Ref}>پیام آلارم حداقل</p>
                      <input
                        type="text"
                        onFocus={focusHandler2}
                        onBlur={blurHandler2}
                      />
                    </div>
                  </span>
                  <span>
                    <div>
                      <p ref={p3Ref}>آلارم حداکثر</p>
                      <input
                        type="number"
                        onFocus={focusHandler3}
                        onBlur={blurHandler3}
                      />
                    </div>
                    <div>
                      <p ref={p4Ref}>پیام آلارم حداکثر</p>
                      <input
                        type="text"
                        onFocus={focusHandler4}
                        onBlur={blurHandler4}
                      />
                    </div>
                  </span>
                </div>
              </div>
              <div className="add__container__modal__wrapper__left">
                <span>
                  <CiRuler />
                  <p>مقیاس</p>
                </span>
                <div className="left__one">
                  <p ref={p5Ref}>واحد</p>
                  <input
                    type="text"
                    onFocus={focusHandler5}
                    onBlur={blurHandler5}
                  />
                </div>
                <div className="left__two">
                  <span>
                    <p ref={p6Ref}>حد بالا</p>
                    <input
                      type="number"
                      onFocus={focusHandler6}
                      onBlur={blurHandler6}
                    />
                  </span>
                  <span>
                    <p ref={p7Ref}>حد پایین</p>
                    <input
                      type="number"
                      onFocus={focusHandler7}
                      onBlur={blurHandler7}
                    />
                  </span>
                </div>
              </div>
            </section>
            <span>
              <p onClick={clickHandler}>بستن پنجره</p>
              <button>دخیره کردن</button>
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
