import React from "react";
import "./Home.css";
import Navbar from "../../Components/Navbar/Navbar";
import Box from "../../Components/Box/Box";
import Add from "../../Components/Add/Add";

export default function Home() {
  return (
    <div className="home__container">
      <div className="home__wrapper">
        <Navbar />
        <div className="home__wrapper__boxes">
          <Box />
        </div>
        <Add />
      </div>
    </div>
  );
}
