import Login from "./Pages/Login/Login";
import Home from './Pages/Home/Home';
import Chart from './Pages/Chart/Chart';
import Users from './Pages/Users/Users';

let AllRoutes = [
    {path : '/' , element : <Login />},
    {path : '/home' , element : <Home />},
    {path : '/chart' , element : <Chart />},
    {path : '/users' , element : <Users />},
]

export default AllRoutes;