import React from "react";
import "./Login.css";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import { BiUser, BiLockAlt } from "react-icons/bi";
import { useState } from "react";

export default function Login() {

  const [value1 , setValue1] = useState('');
  const [value2 , setValue2] = useState('');

  function onChange(value) {
    console.log("Captcha value:", value);
  }

  function changeHandler1 (e) {
    setValue1(e.target.value);
  }

  function changeHandler2 (e) {
    setValue2(e.target.value);
  }

  function clickHandler () {
    let newUser = {
      username : value1,
      password : value2
    }

    fetch('http://localhost:3500/users' , {
      method : "POST",
      headers : {
        "Content-Type" : "application/json"
      },
      body : JSON.stringify(newUser)
    })
      .then(res => console.log(res))

    setValue1('');
    setValue2('');
  }

  return (
    <div className="login__container">
      <div className="login__wrapper">
        <h3>لاگین</h3>
        <div className="login__wrapper__username">
          <p>نام کاربری</p>
          <BiUser />
          <input type="text" placeholder="نام کاربری : " value={value1} onChange={e => changeHandler1(e)} />
        </div>
        <div className="login__wrapper__password">
          <p>رمز عبور</p>
          <BiLockAlt />
          <input type="password" placeholder="رمز عبور :" value={value2} onChange={e => changeHandler2(e)} />
        </div>
        <ReCAPTCHA sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" onChange={onChange} />
        <Link to={"/home"} onClick={clickHandler} >ورود</Link>
      </div>
    </div>
  );
}
