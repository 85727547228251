import React, { useState } from "react";
import "./Chart.css";
import { Link } from "react-router-dom";
import DatePicker from "react-multi-date-picker";
import { Calendar } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";

import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";

import { ImConnection } from "react-icons/im";
import { HiArrowRight } from "react-icons/hi";
import { MdDateRange } from "react-icons/md";

export default function Chart() {
  const data = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];
  const [value, setValue] = useState(new Date());

  return (
    <div className="chart__container">
      <div className="chart__wrapper">
        <div className="chart__wrapper__top">
          <p>متن تستی برای نمایش</p>
          <p>
            <ImConnection />
          </p>
        </div>
        <div className="chart__wrapper__center">
          <span>
            <HiArrowRight />
            {/* <input type="date" /> */}
            <DatePicker
              calendar={persian}
              locale={persian_fa}
              calendarPosition="bottom-right"
            />
          </span>
          <span>
            <LineChart
              width={600}
              height={300}
              data={data}
              margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
            >
              <Line type="monotone" dataKey="uv" stroke="#8884d8" />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
            </LineChart>
          </span>
        </div>
        <div className="chart__Wrapper__bottom">
          <Link to="/home">صفحه اصلی</Link>
        </div>
      </div>
    </div>
  );
}
