import React, { useRef, useState, useEffect } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { AiOutlineMenu, AiOutlineUserAdd } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function Navbar() {
  let activeNavbar = useRef();
  let activeLogin = useRef();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function clickHandler() {
    setIsMenuOpen(false);
  }

  function barsHandler() {
    setIsMenuOpen(true);
  }

  function loginHandler() {
    activeLogin.current.classList.toggle("active");
  }

  function clickhandler2() {
    handleShow();
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        isMenuOpen &&
        activeNavbar.current &&
        !activeNavbar.current.contains(e.target)
      ) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  return (
    <div className="navbar__container" ref={activeNavbar}>
      <div className="navbar__wrapper">
        {isMenuOpen && (
          <aside>
            <div className="navbar__wrapper__aside">
              <FaTimes onClick={clickHandler} />
              <Link to={"/"}>سنسورها</Link>
              <Link to={"/users"}>کاربران</Link>
            </div>
          </aside>
        )}
        <div>
          <AiOutlineMenu onClick={barsHandler} />
          <span>SCADA Monitoring</span>
        </div>
        <div>
          <span>ADMIN</span>
          <AiOutlineUserAdd onClick={loginHandler} />
        </div>
        <div className="editPassword" ref={activeLogin}>
          <p onClick={clickhandler2}>تغییر گذرواژه</p>
          <p>خروج</p>
        </div>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Body className="ModalBodyMe">
            <input
              type="password"
              placeholder="گذرواژه فعلی خود را وارد کنید..."
            />
            <input
              type="password"
              placeholder="گذرواژه جدید خود را وارد کنید..."
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              لغو
            </Button>
            <Button variant="primary" onClick={handleClose}>
              ذخیره تغییرات
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}
