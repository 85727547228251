import React from 'react';
import './App.css';
import AllRoutes from './Routes';
import { useRoutes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';

export default function App() {

  const Routes = useRoutes(AllRoutes);

  return (
    <div>
      {Routes}
    </div>
  )
}
